import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/Seo'
import { PageHeadings } from '../utils/styles'
import styled, { css } from 'react-emotion'
import Img from 'gatsby-image'
import Socials from '../components/Socials.js'

class PageTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark

    return (
      <Layout location={this.props.location}>
        <Seo
          type="page"
          title={post.frontmatter.title}
          description={post.frontmatter.description}
          path={this.props.pageContext.slug}
        />
        <div
          className={css`
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
          `}
        >
          {/* <Img fixed={this.props.data.art.childImageSharp.fixed}  /> */}
          {/* <div className={css`padding-left:2rem; max-width:32rem;`}> */}
          <PageHeadings
            className={css`
              visibility: hidden;
              height: 0rem;
              margin: 0;
            `}
          >
            {post.frontmatter.title}
          </PageHeadings>
          <div
            className={css`
              margin-top: 1rem;
              text-align: justify;
            `}
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
          {/* </div> */}
          <Socials feed={this.props.data.site.siteMetadata.podcastFeed} />
        </div>
      </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    site {
      siteMetadata {
        siteName
        siteUrl
        podcastFeed
      }
    }
    art: file(
      sourceInstanceName: { eq: "assets" }
      relativePath: { eq: "poster.png" }
    ) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        # cropFocus: CENTER
        fixed(width: 300, height: 370) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt
      html
      frontmatter {
        title
        layout
        title
        description
      }
    }
  }
`
